import { makeObservable, observable } from 'mobx';
import LocalStorage from '../views/app/LocalStorage';

export type UnitAge = 'month' | 'year';

export default class Settings {
  @observable
  unitAge: UnitAge;
  allUnitAges: UnitAge[] = ['month', 'year'];

  private _localStorage: LocalStorage;

  constructor(localStorage: LocalStorage) {
    makeObservable(this);
    this._localStorage = localStorage;
    this.unitAge = this._localStorage.getValue('settingsUnitAge') as UnitAge ?? 'year';
  }

  unitAgeChange = (value: UnitAge): void => {
    this.unitAge = value;
    this._localStorage.setValue('settingsUnitAge', value);
  }
}
