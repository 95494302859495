import axios, { AxiosPromise, Canceler } from 'axios';
import { computed, makeObservable } from 'mobx';
import { appModel, DPartialApp } from './models/App';
import { DUnsavedNote } from './models/Notes';
import { ProjectsChangedData } from './models/Projects';
import { ChangedData } from '@simosol/iptim-data-model';

type ErrorData =  {[key: string]: string[]};

type APIResponseBaseError = {
  code?: string,
  data?: ErrorData,
  message?: string,
};

/**
 * base response from server
 */
type ApiResponseBase<S> =
  { success: true; data: S} |
  { success: false; error: APIResponseBaseError};

export enum ErrorType {
  network = 'network',
  other = 'other',
  invalidSession = 'invalid session',
}

type ApiError = { type: ErrorType } & APIResponseBaseError;

/**
 * Response from server after handling all errors
 */
export interface ApiMethodResponse<S = {}> {
  data?: S;
  error?: ApiError;
}

type Transformer<S, T> = (data: S) => T | void;

export type ApiMethodPromise<S> = Promise<ApiMethodResponse<S>> & {
  cancel: Canceler;
  onData: <T>(transformer: Transformer<S, T>) => ApiMethodPromise<T>;
};

type ApiMethodOptions = { noSid: boolean };

export default class API {
  constructor() {
    makeObservable(this);
  }

  @computed
  public get sid() { return appModel.localStorage.sid; }
  private setSid = (value: string | undefined) => { appModel.localStorage.setSid(value); };
  /**
   * Base function for creating API methods
   */
  private _apiMethod = <T = {}>(url: string, data?: {}, options?: ApiMethodOptions) => {
    const cancelSource = axios.CancelToken.source();
    const request = axios.post<ApiResponseBase<T>>(
      url,
      data,
      {
        cancelToken: cancelSource.token,
        headers: (!options || !options.noSid) ? { sid: this.sid } : undefined,
      },
      );
    return this.createApiMethodPromise(this._apiResponse(request), cancelSource.cancel);
  }

  private _apiActionMethod = <T = {}>(action: string, data?: {}, options?: ApiMethodOptions) =>
    this._apiMethod<T>(
      process.env.REACT_APP_API_URL! + '/' + action,
      data,
      options,
    )

  /**
   * Creates the promise, which handles server answer and errors
   */
  private _apiResponse = async <T>(req: AxiosPromise<ApiResponseBase<T>>) => {
    const res: ApiMethodResponse<T> = {};
    try {
      const reqRes = await req;
      const data: ApiResponseBase<T> = reqRes.data;
      if (data.success) {
        res.data = data.data;
      } else {
        res.error = { type: ErrorType.other, ...data.error };
      }
    } catch (e) {
      if (e.response) {
        const data:ApiResponseBase<T> = e.response.data;
        let type =  ErrorType.other;
        if (!data.success) {
          const code = data.error.code;
          // const status = e.response.status;
          if (code === 'IptimException.authInvalidSession') {
            type = ErrorType.invalidSession;
            this.setSid(undefined);
          }
          res.error = { type, ...data.error };
        }
      } else if (e.request) {
        res.error = {
          type: ErrorType.network,
          message: 'No connection',
        };
      } else {
        throw e;
      }
    }
    return res;
  }

  /**
   * Add new methods to api method promise
   * @param {Promise<ApiMethodResponse<S>>} promise
   * @param {Canceler} cancel
   * @returns {ApiMethodPromise<S>}
   * @private
   */
  public createApiMethodPromise = <S>(
    promise: Promise<ApiMethodResponse<S>>,
    cancel: Canceler,
  ) => {
    const tPromise = promise as ApiMethodPromise<S>;
    tPromise.onData = <T>(transformer: Transformer<S, T>) =>
      this.createApiMethodPromise<T>(
        promise.then((value) => {
          const transformedData = value.data ? transformer(value.data) : undefined;
          return { data: transformedData ? transformedData : undefined, error: value.error };
        }),
        cancel,
      );
    tPromise.cancel = cancel;
    return tPromise;
  }

  // ---=== Add API methods here ===---

  sessionStart = (data: { client_id: string, username: string, password: string }) =>
    this._apiActionMethod<{ sessionID: string }>('sessionStart', data, { noSid: true })
      .onData((res) => { this.setSid(res.sessionID); })

  getData = (resKey?: string) => {
    const data = {};
    if (resKey) data['resKey'] = resKey;
    return this._apiActionMethod<DPartialApp>('dataGet', data);
  }

  setDataChanged = (data: { projects: ProjectsChangedData, notes: DUnsavedNote[] }) => {
    // TODO: DELETE AFTER SERVER UPDATE
    const projects = {};
    for (const project in data.projects) {
      projects[project] = data.projects[project].map((el: ChangedData) => {
        const { changedKeys, position } = el;
        if (changedKeys[0].type !== 'change') return;
        const pos = position.map((p) => {
          const newObject = {};
          // @ts-ignore
          delete Object.assign(newObject, p, { ['uid']: p['id'] })['id'];
          return newObject;
        });
        return {
          key: changedKeys[0].key,
          newValue: changedKeys[0].value,
          position: pos,
        };
      });
    }
    return this._apiActionMethod('dataSetChanged',  { ...data, projects });
  }
}
