import * as React from 'react';
import { appModel } from '../../models/App';
import DataPreloader from '../../uicomponents/DataPreloader';
import ListItem from '@material-ui/core/ListItem/ListItem';
import ListItemText from '@material-ui/core/ListItemText/ListItemText';
import { Project } from '../../models/Projects';
import { LangKey } from '../../LangKey';
import Text from '../../uicomponents/Text';
import AppToolbar from '../app/appBar/AppToolbar';
import { observer } from 'mobx-react-lite';

const Projects = observer(() => {
  return (
    <>
      <AppToolbar title={LangKey.ProjectsTitle} button={{ type: 'menu' }}/>
      <DataPreloader>
        {appModel.projects.map(project => (
          <ProjectListItem project={project} key={project.id}/>
        ))}
      </DataPreloader>
    </>
  );
});

const ProjectListItem = observer((props: { project: Project }) => {
  const { project } = props;
  const onProjectClick = () => {
    appModel.projects.showProject(project);
  };
  const progressPercent = Math.round(project.standsCreationProgress * 100);
  const loaded = progressPercent === 100;
/*  const secondary = project.standsMunicipality.length === 1 && project.standsMunicipality[0] === ''
    ? LangKey.ProjectsYouStandsCount.t({ count: project.standsCount })
    : `${project.standsMunicipality}, ${LangKey.ProjectsYouStandsCount.t({ count: project.standsCount })}`;*/

  return (
    <ListItem disabled={!loaded} button key={project.id} onClick={onProjectClick}>
      <ListItemText
        primary={project.name}
        secondary={
          loaded
            ? `${project.standsMunicipality} ${LangKey.ProjectsYouStandsCount.t({ count: project.standsCount })}`
            : LangKey.ProjectsLoading.t({ percent: progressPercent })
        }
      />
      {loaded && <Text>{LangKey.ProjectsArea.t({ area: project.area })}</Text>}
    </ListItem>
  );
});

export default Projects;
