import * as React from 'react';
import NoteEditForm  from './NoteEditForm';
import IconButton from '../../uicomponents/IconButton';
import { default as SaveIcon } from '@material-ui/icons/Check';
import DataPreloader from '../../uicomponents/DataPreloader';
import { LangKey } from '../../LangKey';
import AppToolbar from '../app/appBar/AppToolbar';
import { appModel } from '../../models/App';
import { PageTask } from '../../Browser';
import { Form } from '@simosol/forms/lib/forms';
import { required } from '../../uicomponents/forms/FormRules';
import { DEditableNote } from '../../models/Notes';
import { observer } from 'mobx-react-lite';

type NoteEditorProps =
  { state: 'new'; } |
  { state: 'edit', noteId: string };

type Props = NoteEditorProps;

const NoteEditor = observer((props: Props) => {
  const { state } = props;
  const [formData, setFormData] = React.useState<DEditableNote>();
  const [formModel, setFormModel] = React.useState<Form<DEditableNote>>();
  const noteId: string | undefined = props.state !== 'new' ? props.noteId : undefined;
  const note = noteId ? appModel.tasks.getNote(noteId) : undefined;

  React.useEffect(
    () => {
      const formData = note
        ? { text: note.text, gpsCoords: note.gpsCoords, gpsAccuracy: note.gpsAccuracy }
        : { text: '' };
      setFormData(formData);
      setFormModel(
        new Form<DEditableNote>(
          formData,
          { text: required() },
        ));
    },
    [note]);

  const save = () => {
    const currentTask = appModel.currentTask;
    if (!currentTask || !formModel || !formData) return;
    currentTask.notes.addOrEditNote(formData, note);
    back();
  };
  const back = () => {
    if (!appModel.currentTask) return;
    appModel.browser.page = { p: 'task', p1: appModel.currentTask.id, p2: PageTask.notes, p3: 'view' };
  };

  return (
    <>
      <AppToolbar
        button={{ type: 'back', onClick: back }}
        title={state === 'new' ? LangKey.NotesTitleAdd : LangKey.NotesTitleEdit}
      >
        <div style={{ flexGrow: 1 }}/>
        <IconButton color={'inherit'} onClick={save}>
          <SaveIcon/>
        </IconButton>
      </AppToolbar>
      <DataPreloader>
        {formModel && <NoteEditForm form={formModel}/>}
      </DataPreloader>
    </>
  );
});

export default NoteEditor;
