import * as React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import FormLabel from '@material-ui/core/FormLabel';
import { LangKey } from '../../LangKey';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import FormControl from '@material-ui/core/FormControl';
import { appModel } from '../../models/App';
import { translateUnit } from '../../models/DataNodeUtils';
import { UnitAge } from '../../models/Settings';
import { observer } from 'mobx-react-lite';

const useStyles = makeStyles((theme: Theme) => createStyles({
  group: {
    margin: theme.spacing(3, 0),
  },
}));

const AgeKindSelector = observer(() => {
  const classes = useStyles();
  const settings = appModel.settings;
  const onRadioChange = (event: React.ChangeEvent<{}>, value: string) => {
    settings.unitAgeChange(value as UnitAge);
  };
  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">{LangKey.SettingsUnitAge.t()}</FormLabel>
      <RadioGroup
        className={classes.group}
        value={settings.unitAge}
        onChange={onRadioChange}
      >
        { settings.allUnitAges.map(unitAge => (
          <FormControlLabel
            key={unitAge}
            value={unitAge}
            control={<Radio color={'default'} />}
            label={translateUnit(unitAge)}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
});

export default AgeKindSelector;
