import * as React from 'react';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import DynamicElementProp from './DynamicElementProp';
import { default as classNames } from 'classnames';
import Text from '../../uicomponents/Text';
import { DataNodeProp as MDynamicElementProp } from '@simosol/iptim-data-model';
import withStyles, { WithStyles } from '@material-ui/styles/withStyles';
import createStyles from '@material-ui/styles/createStyles';

const borderColor = 'rgba(0,0,0,0.08)';
const border = '1px solid ' + borderColor;
const styles = (theme: Theme) => createStyles({
  root: {
  },
  borderBottom: {
    borderBottom: border,
  },
  header: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    paddingLeft: 12,
  },
  borderTopBottom: {
    borderBottom: border,
    borderTop: border,
  },
});

type Props = {
  model: MDynamicElementProp[];
  className?: string;
  headerLabel?: string;
} & WithStyles<typeof styles>;

class DynamicElementProps extends React.Component<Props> {
  render() {
    const { classes, model, className, headerLabel } = this.props;
    return (
      <div className={classNames(classes.root, className)}>
        {headerLabel &&
          <Text className={classes.header} variant={'h6'}>{headerLabel}</Text>
        }
        {model.map((item, index) => (
          <React.Fragment key={item.key}>
            <DynamicElementProp
              key={item.key}
              item={item}
              className={index === 0 ? classes.borderTopBottom : classes.borderBottom}
            />
          </React.Fragment>
        ))}
      </div>
    );
  }
}

export default withStyles(styles)(DynamicElementProps);
