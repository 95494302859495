import * as React from 'react';
import { default as MuiIconButton, IconButtonProps as MuiIconButtonProps } from '@material-ui/core/IconButton';
import { Page } from '../Browser';
import { appModel } from '../models/App';
import { Utils } from '../Lang';

export type IconButtonProps = {
  page?: Page;
} & MuiIconButtonProps;

class IconButton extends React.Component<IconButtonProps> {
  onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (this.props.onClick) this.props.onClick(e);
    if (this.props.page !== undefined) appModel.browser.page = this.props.page;
  }

  render() {
    const props: IconButtonProps = { ...this.props };
    props.onClick = this.onClick;
    props.className = 'IconButton ' + (props.className ? props.className : '');
    return React.createElement(MuiIconButton, props, Utils.translateStringChildren(this.props.children));
  }
}

export default IconButton;
