import Browser from '../Browser';
import API from '../API';
import { action, computed, makeObservable, observable } from 'mobx';
import DB from './DB';
import DataSync from './DataSync';
import { default as MapModel } from './Map';
import LocalStorage from '../views/app/LocalStorage';
import Projects, { DPartialProject, DProject } from './Projects';
import { Stands } from './Stands';
import { Tasks } from './Tasks';
import Settings from './Settings';

export enum UserRole {
  assignee = 'assignee',
  manager = 'manager',
  auditor = 'auditor',
}

export interface User {
  uid: string;
  username: string;
}

export interface DApp {
  projects: DProject[];
  role: UserRole;
  users: User[];
}

export interface DPartialApp {
  projects: DPartialProject[];
  role: UserRole;
  users?: User[];
  numParts: number;
  hasMore: boolean;
  resKey: string;
}

class App {
  browser = new Browser();
  api = new API();

  private _db = new DB();
  private _dataSync = new DataSync(this._db);

  localStorage = new LocalStorage();
  settings = new Settings(this.localStorage);
  projects = new Projects();
  stands = new Stands();
  tasks = new Tasks();

  @observable
  userRole?: UserRole;

  users: User[] = [];

  map = new MapModel();

  @observable
  toolBarHeight?: number;
  @observable
  drawerOpen?: boolean = false;

  constructor() {
    makeObservable(this);
  }

  init = () => {
    this.tasks.init();
    this._dataSync.init();
  }

  get isTapio() {
    return !!process.env.REACT_APP_TAPIO_COMPANY;
  }

  @computed
  get authorized() {
    return !!this.localStorage.sid;
  }

  @action
  logout = () => {
    this.localStorage.setSid(undefined);
  }
  @computed
  get currentProject() {
    if (this.browser.page.p === 'project') {
      const project = this.projects.get(this.browser.page.p1);
      if (project) return project;
    }
    return undefined;
  }

  @computed get currentTask() {
    return this.tasks.currentTask;
  }

  @computed
  get dataInitialized() { return this._dataSync.initialized; }

  @computed
  get unsavedData() { return this._dataSync.unsavedData; }

  @computed
  get dataInitializePercent(): number {
    const progress = this._dataSync.initProgress;
    return (typeof progress === 'number' ? progress : progress()) * 100;
  }

  @computed
  get criticalError() { return this._dataSync.criticalError; }

  @action
  updateFromData = async (data: DApp) => {
    this.projects.clear();
    this.projects.fromArray(data.projects);
    this.userRole = data.role;
    this.users = data.users;
    return this.projects.createStands();
  }

  getAppData = (): DApp | undefined => {
    // don't check here datainitialized, because this function is used in data initialization

    if (!this.userRole || !this.users) return;
    return {
      projects: this.projects.map(project => project.data),
      role: this.userRole,
      users: this.users,
    };
  }
}

export const appModel = new App();
appModel.init();
