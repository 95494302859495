import { computed, makeObservable, observable } from 'mobx';

type LocalStorageKeys = 'settingsUnitAge';

export default class LocalStorage {
  @observable
  private _sid?: string =
    localStorage.getItem('sid') !== null ? localStorage.getItem('sid') as string : undefined;

  constructor() {
    makeObservable(this);
  }

  public setSid(value: string | undefined) {
    (this._sid = value) === undefined ?
      localStorage.removeItem('sid') :
      localStorage.setItem('sid', this._sid);
  }

  @computed
  get sid() { return this._sid; }

  setValue = (key: LocalStorageKeys, value: string | undefined) => {
    const keyLS = this._getKeyLS(key);
    if (value === undefined) {
      localStorage.removeItem(keyLS);
    } else {
      localStorage.setItem(keyLS, value);
    }
  }

  getValue = (key: LocalStorageKeys) => {
    return localStorage.getItem(this._getKeyLS(key));
  }

  private _getKeyLS = (key: LocalStorageKeys) => {
    return `iptim_mobile ${key}`;
  }

  setMapDownload = (mapDownload: {[key: string]: boolean}) => {
    localStorage.setItem('mapDownload', JSON.stringify(mapDownload));
  }

  getMapDownload = () => {
    const md = localStorage.getItem('mapDownload');
    if (md !== null) return JSON.parse(md);
    return undefined;
  }
}
