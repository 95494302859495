import * as React from 'react';
import { grey } from '@material-ui/core/colors';
import { appModel } from '../models/App';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import { observer } from 'mobx-react';

@observer
class DataPreloader extends React.Component {
  render() {
    if (!appModel.dataInitialized) {
      return <CircularProgress style={{ margin: 32, color: grey[500] }}/>;
    } else {
      return this.props.children || null;
    }
  }
}

export default DataPreloader;
