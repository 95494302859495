import * as React from 'react';
import DataPreloader from '../../uicomponents/DataPreloader';
import { appModel } from '../../models/App';
import AppToolbar from '../app/appBar/AppToolbar';
import { Stand } from '../../models/Stands';

import { observer } from 'mobx-react-lite';
import MapOpenButton from '../map/MapOpenButton';
import StandListItem from './StandListItem';
// import { createStyles, makeStyles } from '@material-ui/styles';
// import { Theme } from '@material-ui/core/styles/createMuiTheme';
// import { SortButtonModel } from '../tasks/SortButton';
// import { observable } from 'mobx';
// import { LangKey } from '../../LangKey';

/*const useStyles = makeStyles((theme: Theme) => createStyles({
  spacer: {
    flexGrow: 1,
  },
  customizeButton: {
    margin: theme.spacing(.5, 1),
  },
}));*/

const onStandClick = (stand: Stand) => () => {
  appModel.stands.showStand(stand);
};

const Project = observer(() => {
  /*const styles = useStyles();*/
  const project = appModel.currentProject;
  const hasGeo = project?.stands.some(stand => stand.geoJSONToMap);
/*  const sortModel: SortButtonModel<'taskId' | 'status' | 'dateAsc' | 'dateDesc'> = observable({
    items: [
      { label: LangKey.TasksSortId, value: 'taskId' },
      { label: LangKey.TasksSortStatus, value: 'status' },
      { label: LangKey.TasksSortDateAsc, value: 'dateAsc' },
      { label: LangKey.TasksSortDateDesc, value: 'dateDesc' },
    ],
    value: 'taskId',
  });*/
  return (
    <>
      <AppToolbar
        title={appModel.currentProject ? appModel.currentProject.name : ''}
        button={{ type: 'back', page: { p: 'projects' } }}
      >
        {/*<div className={styles.spacer}/>
        <Button
          className={styles.customizeButton}
          color={'inherit'}
          variant={'outlined'}
          onClick={() => console.log('Choose parameters')}
        >
          Customize view
        </Button>
        <SortButton model={sortModel} />*/}
      </AppToolbar>
      <DataPreloader>
        {project &&
          <>
            {project.stands.map(stand => (
              <StandListItem
                onStandClick={onStandClick(stand)}
                stand={stand}
                key={stand.id}
              />
            ))}
            {hasGeo && <MapOpenButton onClick={() => appModel.map.showMap(project.id)} />}
          </>
        }
      </DataPreloader>
    </>
  );
});

export default Project;
