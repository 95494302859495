import * as React from 'react';
import { appModel, UserRole } from '../../models/App';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import PowerSettingsNew from '@material-ui/icons/PowerSettingsNew';
import Assignment from '@material-ui/icons/Assignment';
import { Page } from '../../Browser';
import { LangKey } from '../../LangKey';
import ListItemText from '../../uicomponents/ListItemText';
import { default as ListIcon } from '@material-ui/icons/List';
import { default as SettingsIcon } from '@material-ui/icons/Settings';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import { observer } from 'mobx-react';
import Confirm from '../../uicomponents/Confirm';

type MainMenuButtonProps = {
  selected: boolean;
  clickPage: Page;
  label: string;
  icon: React.ReactElement<SvgIconProps>;
};

class MainMenuButton extends React.Component<MainMenuButtonProps> {
  private _onClick = () => {
    appModel.drawerOpen = false;
    appModel.browser.page = this.props.clickPage;
  }
  render() {
    const { selected, label, icon } = this.props;
    return (
      <ListItem
        button
        selected={selected}
        onClick={this._onClick}
      >
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText>{label}</ListItemText>
      </ListItem>
    );
  }
}

interface MainMenuProps {
  page: Page;
}

@observer
class MainMenu extends React.Component<MainMenuProps> {
  render() {
    const { page } = this.props;
    const p = page.p;
    const userRole = appModel.userRole;
    return (
      <>
        {(userRole === UserRole.manager || userRole === UserRole.auditor) &&
        <MainMenuButton
          selected={p === 'projects' || p === ''}
          clickPage={{ p: 'projects' }}
          label={LangKey.ProjectsTitle}
          icon={<ListIcon /> as React.ReactElement<SvgIconProps>}
        />
        }
        <MainMenuButton
          selected={p === 'tasks' || (p === '' && userRole === UserRole.assignee)}
          clickPage={{ p: 'tasks' }}
          label={LangKey.TasksTitle}
          icon={<Assignment/> as React.ReactElement<SvgIconProps>}
        />
        <MainMenuButton
          selected={p === 'settings'}
          clickPage={{ p: 'settings' }}
          label={LangKey.SettingsTitle}
          icon={<SettingsIcon/> as React.ReactElement<SvgIconProps>}
        />
        <Confirm
          message={LangKey.AuthLogoutConfirm.t()}
          variant={'menuItem'}
          onClick={appModel.logout}
          icon={<PowerSettingsNew/>}
        >
          {LangKey.AuthLogoutButton.t()}
        </Confirm>
      </>
    );
  }
}

export default MainMenu;
