import * as React from 'react';
import { DatePicker } from '@material-ui/pickers';
import { Moment, default as moment } from 'moment';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { DataNodePropDate } from '../../../models/DataNodeUtils';
import { DESCRIPTOR_TYPE_PROP } from '@simosol/iptim-data-model';

type DatePropEditorProps = {
  item: DataNodePropDate;
  open: boolean;
  onClose: () => void;
};

const EditorDate = (props: DatePropEditorProps) => {
  const { item, open, onClose } = props;
  const [selectedDate, setSelectedDate] = React.useState<Moment>(moment(item.value));

  const onChange = (date: MaterialUiPickersDate) => {
    setSelectedDate(date as Moment);
  };

  const onAccept = (date: MaterialUiPickersDate) => {
    const mDate = date as Moment;
    item.value = {
      month: mDate.month(),
      date: mDate.date(),
      year: mDate.year(),
      [DESCRIPTOR_TYPE_PROP]: 'iptim.amf.SimpleDate',
    };
    onClose();
  };

  return (
    <DatePicker
      inputProps={{ style: { display: 'none' } }}
      open={open}
      onClose={onClose}
      value={selectedDate}
      views={['year', 'month', 'date']}
      onChange={onChange}
      onAccept={onAccept}
    />
  );
};

export default EditorDate;
