import * as React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';
import MapStandInfo from './MapStandInfo';
import StandMapBase from './StandMapBase';

const useStyles = makeStyles(createStyles({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  map: {
    flexGrow: 1,
  },
}));

const AppMap = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <StandMapBase />
      <MapStandInfo />
    </div>
  );
};

export default AppMap;
