import PouchDB from 'pouchdb';
// import PutDocument = PouchDB.Core.PutDocument;
import { DApp } from './App';
import { toJS } from 'mobx';
import { DProject } from './Projects';
import { DDataNode } from '@simosol/iptim-data-model';

export default class DB {
  private _projectsDocId = 'appData';
  private _projectsDBName = 'projects';
  private _standsDBName = 'projects_stands';
  private _createNew = (dbName: string, size?: number) => {
    return new PouchDB(dbName, { size, auto_compaction: true });
  }
  private _dbProjects = this._createNew(this._projectsDBName);
  private _dbStands = this._createNew(this._standsDBName);

  get = async <T>() => {
    const projectsRes = await this._dbProjects.get<{ data: DApp }>(this._projectsDocId);
    const appData = projectsRes.data;
    const allStandsDB = await this._dbStands.allDocs<{ stand: DDataNode }>({ include_docs: true });
    const projectStands: {[key: string]: DDataNode[]} = {};

    for (const standRow of allStandsDB.rows) {
      if (!standRow.doc) continue;
      const id = standRow.doc._id;
      const idParts = id.split('_%%%_');
      const projectId = idParts[0];
      if (!projectStands[projectId]) projectStands[projectId] = [];
      projectStands[projectId].push(standRow.doc.stand);
    }
    for (const project of appData.projects) {
      if (projectStands[project.uid]) {
        project.data = projectStands[project.uid];
      }
    }

    return appData;
  }

  updateOrInsert = async (data: DApp) => {
    const newData = { ...toJS(data) };
    const projectsWithoutStands: DProject[] = [];
    let allStandRows = undefined;
    try {
      allStandRows = await this._dbStands.allDocs<{ stand: DDataNode }>({ include_docs: false });
    } catch (e) {
      if (e.status !== 404) {
        throw (e);
      }
    }

    const standDocs: { _id: string, stand: DDataNode, _rev?: string }[] = [];
    for (const project of newData.projects) {
      const stands = project.data;
      projectsWithoutStands.push({ ...project, data: [] });
      for (const stand of stands) {
        const rowId = project.uid + '_%%%_' + stand.uid;
        let rev = undefined;
        if (allStandRows) {
          for (const standRow of allStandRows.rows) {
            if (standRow.id === rowId) {
              rev = standRow.value.rev;
              break;
            }
          }
        }
        standDocs.push({ stand, _id: rowId, _rev: rev });
      }
    }
    const standDocsSlices: ({ _id: string, stand: DDataNode }[])[] = [];
    const chunk = 10;
    for (let i = 0, j = standDocs.length; i < j; i += chunk) {
      standDocsSlices.push(standDocs.slice(i, i + chunk));
    }

    for (const standDocsSlice of standDocsSlices) {
      await this._dbStands.bulkDocs(standDocsSlice);
    }

    newData.projects = projectsWithoutStands;
    return this._updateOrInsert(this._dbProjects, { data: newData, _id: this._projectsDocId });
  }

  destroyAndCreateNew = async () => {
    await this._dbProjects.destroy();
    await this._dbStands.destroy();
    this._dbProjects = this._createNew(this._projectsDBName);
    this._dbStands = this._createNew(this._standsDBName);
  }

  /**
   * update or insert data into DB
   */
  private _updateOrInsert = async <T extends { _id: string, _rev?: string }>
  (db: PouchDB.Database, doc: T) => {
    try {
      const dbDoc = await db.get(doc._id);
      doc._rev = dbDoc._rev;
    } catch (e) {
      // TODO handle critical errors
    }
    await db.put(doc as PouchDB.Core.PutDocument<T>);
    return doc;
  }
}
