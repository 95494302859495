import * as React from 'react';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { createStyles, makeStyles } from '@material-ui/styles';
import { default as MapIcon } from '@material-ui/icons/Map';
import Fab from '../../uicomponents/Fab';

const useStyles = makeStyles((theme: Theme) => createStyles({
  mapIcon: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

type MapOpenButtonProps = {
  onClick: () => void;
};

const MapOpenButton = (props: MapOpenButtonProps) => {
  const classes = useStyles();
  return (
    <Fab
      color={'primary'}
      size={'large'}
      className={classes.mapIcon}
      onClick={props.onClick}
    >
      <MapIcon/>
    </Fab>
  );
};

export default MapOpenButton;
