import * as React from 'react';
import FormTextField from '../../../uicomponents/forms/FormTextField';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import Button from '../../../uicomponents/Button';
import { LangKey } from '../../../LangKey';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import InputAdornment from '@material-ui/core/InputAdornment/InputAdornment';
import { Form } from '@simosol/forms/lib/forms';
import * as formRules from '../../../uicomponents/forms/FormRules';
import { DataNodePropNumberWrapper, DataNodePropWrapper } from '../../../models/DataNodeUtils';

type DynamicElementPropEditorProps = {
  item: DataNodePropWrapper;
  className?: string;
  open: boolean;
  onClose: () => void;
};

const EditorNumberString = (props: DynamicElementPropEditorProps) => {
  const { onClose, item, open } = props;

  const formValue: string = item.valueString;

  const model = React.useMemo(
    () => {
      const rulesArr = [];

      if (item instanceof DataNodePropNumberWrapper) {
        rulesArr.push(formRules.number());
        const min = item.minimum;
        if (min !== undefined) {
          rulesArr.push(formRules.min(min));
        }
        const max = item.maximum;
        if (max !== undefined) {
          rulesArr.push(formRules.max(max));
        }
      }
      return new Form({ value: formValue }, { value: rulesArr });
    },
    [item]);

  const save = () => {
    if (!model.validateAll()) return;
    const modelValue = model.getValue('value');
    item.setValueFromString(modelValue);
    onClose();
  };

  const cancel = () => {
    model.setValue('value', formValue);
    model.clear();
    onClose();
  };

  return (
    <Dialog keepMounted open={open} onClose={cancel} fullWidth maxWidth={'xs'}>
      <DialogContent>
        <FormTextField
          margin={'dense'}
          field={'value'}
          label={item.displayName}
          model={model}
          fullWidth
          InputProps={
            (item instanceof DataNodePropNumberWrapper && item.unit)
              ? { endAdornment: <InputAdornment position="end">{item.unit}</InputAdornment> }
              : undefined
          }
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={cancel}>{LangKey.CommonCancel}</Button>
        <Button onClick={save}>{LangKey.CommonOk}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditorNumberString;
