import { ApiMethodResponse } from '../API';
import { appModel, DApp, DPartialApp } from './App';
import { makeObservable, observable } from 'mobx';

export default class DataLoader {
  @observable
  partsLoaded = 0;

  @observable
  partsTotal?: number;

  constructor() {
    makeObservable(this);
  }

  load = async (): Promise<ApiMethodResponse<DApp>> => {
    const res = await appModel.api.getData();
    if (!res.error && res.data) {
      this.partsLoaded = 1;
      this.partsTotal = res.data.numParts;
      const appData: DApp = {
        projects: [],
        role: res.data.role,
        users: [],
      };
      this._mergeAppData(appData, res.data);
      let resKey = res.data.resKey;
      let hasMore = res.data.hasMore;
      while (hasMore) {
        const res = await appModel.api.getData(resKey);
        this.partsLoaded += 1;
        if (!res.error && res.data) {
          this._mergeAppData(appData, res.data);
          hasMore = res.data.hasMore;
          if (res.data.resKey) {
            resKey = res.data.resKey;
          }
        } else {
          return { error: res.error };
        }
      }
      return { data: appData };
    } else {
      return { error: res.error };
    }
  }

  private _mergeAppData = (target: DApp, newData: DPartialApp) => {
    if (newData.users) target.users = newData.users;
    for (const newProject of newData.projects) {
      let exists = false;
      for (const project of target.projects) {
        if (newProject.uid === project.uid) {
          exists = true;
          project.data = project.data.concat(newProject.data);
        }
      }
      if (!exists && newProject.structure) {
        target.projects.push({
          uid: newProject.uid,
          structure: newProject.structure,
          data: newProject.data,
          name: newProject.name,
        });
      }
    }
  }
}
