export class LangKey {
  static UpdateDialogChecking = 'updateDialog.checking';
  static UpdateDialogDownloading = 'updateDialog.downloading';
  static UpdateDialogInstalling = 'updateDialog.installing';
  static UpdateDialogInstalled = 'updateDialog.installed';

  static AuthHeader = 'Auth.header';
  static AuthTitle = 'Auth.title';
  static AuthClient = 'Auth.client';
  static AuthLogin = 'Auth.login';
  static AuthPassword = 'Auth.password';
  static AuthLoginButton = 'Auth.loginButton';
  static AuthLogoutButton = 'Auth.logoutButton';
  static AuthLogoutConfirm = 'Auth.logoutConfirm';

  static ProjectsTitle = 'projects.title';
  static ProjectsList = 'projects.list';
  static ProjectsMap = 'projects.map';
  static ProjectsStandsCount = 'projects.standsCount';
  static ProjectsYouStandsCount = 'projects.youStandsCount';
  static ProjectsArea = 'projects.area';
  static ProjectsLoading = 'projects.loading';

  static ProjectTitle = 'project.title';

  static TasksTitle = 'Tasks.title';
  static TasksSortId = 'Tasks.sort.id';
  static TasksSortStatus = 'Tasks.sort.status';
  static TasksSortDateAsc = 'Tasks.sort.dateAsc';
  static TasksSortDateDesc = 'Tasks.sort.dateDesc';

  static TaskTitle = 'task.title';
  static TaskInfo = 'task.info';
  static TaskStand = 'task.stand';
  static TaskNotes = 'task.notes';
  static TaskMap = 'task.map';
  static TaskStatusAssigned = 'task.status.assigned';
  static TaskStatusOnGoing = 'task.status.onGoing';
  static TaskStatusExecuted = 'task.status.executed';

  static StandArea = 'stand.area';
  static StandInfo = 'stand.info';
  static StandStandInfo = 'stand.standInfo';

  static NotesTitleAdd = 'notes.titleAdd';
  static NotesTitleEdit = 'notes.titleEdit';
  static NotesMessage = 'notes.message';
  static NotesGps = 'notes.gps';
  static NotesAccuracy = 'notes.accuracy';

  static MapBaseLayersLoisto = 'map.baseLayers.loisto';
  static MapBaseLayersOsm = 'map.baseLayers.osm';
  static MapBaseLayersMbStreets = 'map.baseLayers.mbStreets';
  static MapBaseLayersMpSatellite = 'map.baseLayers.mpSatellite';
  static MapBaseLayersMpTerrain = 'map.baseLayers.mpTerrain';
  static MapBaseLayersMpDark = 'map.baseLayers.mpDark';
  static MapBaseLayersMpLight = 'map.baseLayers.mpLight';

  static DownloadMapsTitle = 'downloadMaps.title';
  static DownloadMapsDownloadAllButton = 'downloadMaps.downloadAllButton';
  static DownloadMapsMapType = 'downloadMaps.mapType';

  static FormsErrorRequired = 'forms.error.required';
  static FormsErrorNumber = 'forms.error.number';
  static FormsErrorMin = 'forms.error.min';
  static FormsErrorMax = 'forms.error.max';

  static SettingsTitle = 'settings.title';
  static SettingsLanguage = 'settings.language';
  static SettingsUnitAge = 'settings.unitAge';

  static DynamicPropertyAddValue = 'dynamicProperty.add';

  static CommonOk = 'common.ok';
  static CommonCancel = 'common.cancel';
  static CommonUnknownCriticalError = 'common.unknownCriticalError';
  static CommonUnknownSynchronizing = 'common.synchronizing';

  static ConfirmDefault = 'confirm.default';
  static ConfirmYes = 'confirm.yes';
  static ConfirmNo = 'confirm.no';

  static UnitsYear = 'units.year';
  static UnitsYearPlural = 'units.year_plural';
  static UnitsMonth = 'units.month';
  static UnitsMonthPlural = 'units.month_plural';
  static UnitsDay = 'units.day';
  static UnitsDayPlural = 'units.day_plural';
  static UnitsCount = 'units.count';
  static UnitsCountHa = 'units.count/Ha';
  static UnitsMm = 'units.mm';
  static UnitsCm = 'units.cm';
  static UnitsDm = 'units.dm';
  static UnitsM = 'units.m';
  static UnitsKm = 'units.km';
  static UnitsCm2 = 'units.cm2';
  static UnitsM2 = 'units.m2';
  static UnitsHa = 'units.ha';
  static UnitsKm2 = 'units.km2';
  static UnitsDm3 = 'units.dm3';
  static UnitsM3 = 'units.m3';
  static UnitsM3Ha = 'units.m3/ha';
  static UnitsTon = 'units.ton';
  static UnitsKg = 'units.kg';
  static UnitsPercent = 'units.percent';
}
