import * as React from 'react';
import { Stand } from '../../models/Stands';
import { Stand as MapStand } from '@simosol/stands-map';

const useMapStands = (stands: Stand[]): MapStand[] => {
  const getStands = (): MapStand[] => {
    const newStand: MapStand[] = [];
    stands.forEach((stand: Stand) => {
      if (!stand.geoJSONToMap) return;
      newStand.push({
        id: stand.id,
        geoJSON: stand.geoJSONToMap,
        priority: () => stand.priority,
        extensionLabel: stand.name,
      });
    });
    return newStand;
  };
  return React.useMemo(
    () => getStands(),
    [stands]);
};

export default useMapStands;
