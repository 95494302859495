import * as React from 'react';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import Drawer from '@material-ui/core/Drawer/Drawer';
import Hidden from '@material-ui/core/Hidden/Hidden';
import { observer } from 'mobx-react';
import Menu from '@material-ui/icons/Menu';
import IconButton from '../../uicomponents/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import { TypographyProps } from '@material-ui/core/Typography';
import { appModel } from '../../models/App';

const useStyles = makeStyles((theme: Theme) => createStyles({
  drawerPaper: {
    width: theme.appDrawer.width,
    backgroundColor: 'white',
  },
}));

type AppDrawerProps = {};
type AppDrawerPropsWithStyles = AppDrawerProps & TypographyProps;

const AppDrawerBase = observer((props: AppDrawerPropsWithStyles) => {
  const style = useStyles();
  return (
    <>
      <Hidden lgUp>
        <Drawer
          classes={{ paper: style.drawerPaper }}
          open={appModel.drawerOpen}
          onClose={() => appModel.drawerOpen = false}
        >
          {props.children}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer classes={{ paper: style.drawerPaper }} variant={'permanent'}>
          {props.children}
        </Drawer>
      </Hidden>
    </>
  );
});

export default AppDrawerBase;

type AppDrawerMenuButtonProps = {
  className?: string;
};

export const AppDrawerMenuButton = (props: AppDrawerMenuButtonProps) =>  (
  <IconButton color={'inherit'} className={props.className} onClick={() => appModel.drawerOpen = true}>
    <Menu/>
  </IconButton>
);
