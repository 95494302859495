import { computed, makeObservable } from 'mobx';
import { appModel } from './App';
import { Page } from '../Browser';

export default class Map {
  private _backPage?: Page;

  constructor() {
    makeObservable(this);
  }

  @computed
  get currentProject() {
    if (appModel.browser.page.p === 'map') {
      const project = appModel.projects.get(appModel.browser.page.p1);
      if (project && project.allStandsCreated) return project;
    }
    return undefined;
  }

  @computed
  get currentStand() {
    const project = this.currentProject;
    if (!project) return undefined;
    const page = appModel.browser.page;
    if (page.p === 'map') {
      if (page.p2 !== undefined) {
        return project.getStand(page.p2);
      }
    }
    return undefined;
  }

  showMap = (projectId: string, standId?: string) => {
    this._backPage = appModel.browser.page;
    const newPage: Page = { p: 'map', p1: projectId };
    if (standId) {
      newPage.p2 = standId;
    }
    appModel.browser.page = newPage;
  }

  back = () => {
    appModel.browser.page = this._backPage || { p: '' };
  }
}
