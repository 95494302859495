import * as React from 'react';
import CenterContainer from '../../uicomponents/CenterContainer';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles(theme => createStyles({
  progress: {
    width: '100%',
    maxWidth: 200,
    height: theme.spacing(3),
  },
}));

const AppPreloader = (props: { progressPercent: number, message?: string }) => {
  const classes = useStyles();
  return (
    <CenterContainer>
      <LinearProgress className={classes.progress} variant={'determinate'} value={props.progressPercent}/>
    </CenterContainer>
  );
};

export default AppPreloader;
