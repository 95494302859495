import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { appModel } from '../../models/App';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';
import Text from '../../uicomponents/Text';
import { VBox } from '../../uicomponents/Boxes';
import Button from '../../uicomponents/Button';
import { LangKey } from '../../LangKey';

type MapStandInfoProps = {};

const useStyles = makeStyles(theme => createStyles({
  root: {
    padding: theme.spacing(1),
  },
}));

const MapStandInfo = observer((props: MapStandInfoProps) => {
  const classes = useStyles();
  const stand = appModel.map.currentStand;
  if (!stand) return null;
  return (
    <div className={classes.root}>
      <VBox gap={8}>
        <div>
          <Text variant={'h6'}>{stand.name}</Text>
        </div>
        <Button
          size={'small'}
          color={'primary'}
          variant={'contained'}
          onClick={() => appModel.stands.showStand(stand)}
        >
          {LangKey.StandStandInfo}
        </Button>
      </VBox>
    </div>
  );
});

export default MapStandInfo;
