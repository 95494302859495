import * as React from 'react';
import IconButton from '../../uicomponents/IconButton';
import Sort from '@material-ui/icons/Sort';
import Menu from '@material-ui/core/Menu/Menu';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import { observer } from 'mobx-react-lite';

type SortButtonMenuItem<T extends string = string> = { value: T, label: string };

export type SortButtonModel<T extends string = string> = {
  items: SortButtonMenuItem<T>[];
  value: T;
};

type SortButtonProps<T extends string = string> = { model: SortButtonModel<T> };

const SortButton = observer(<T extends string = string>(props: SortButtonProps<T>) => {
  const { model } = props;
  const [buttonRef, setButtonRef] = React.useState<null | HTMLElement>(null);
  const onItemClick = (item: SortButtonMenuItem<T>) => () => {
    model.value = item.value;
    setButtonRef(null);
  };

  const onButtonClick = (e: React.MouseEvent<HTMLElement>) => {
    setButtonRef(e.currentTarget);
  };
  const onClose = () => {
    setButtonRef(null);
  };

  return (
    <>
      <IconButton onClick={onButtonClick} color={'inherit'}>
        <Sort/>
      </IconButton>
      <Menu
        anchorEl={buttonRef}
        keepMounted
        open={!!buttonRef}
        onClose={onClose}
      >
        {model.items.map(item => (
          <MenuItem
            key={item.value}
            selected={item.value === model.value}
            onClick={onItemClick(item)}
          >
            {item.label.t()}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
});

export default SortButton;
