import { action, computed, makeObservable } from 'mobx';
import { Task } from './Tasks';
import arraySearch from '../arraySearch';
import * as uuid from 'uuid';
import { default as cloneDeep } from 'clone-deep';
import { DataNode, DataNodePropComplex } from './DataNodeUtils';
import { DescriptorType } from '@simosol/iptim-data-model';

export interface DUnsavedNote extends DEditableNote {
  projectId: string;
  standId: string;
  taskId: string;
  uid: string;
}

export interface DEditableNote {
  text: string;
  gpsCoords?: string;
  gpsAccuracy?: string;
}

export class Notes {
  private readonly _task: Task;
  constructor(task: Task) {
    makeObservable(this);
    this._task = task;
  }

  @computed
  get operation() {
    return this._task.operation;
  }

  @computed
  get notesProp(): DataNodePropComplex | undefined {
    return this.operation.getProp('op_notes', DescriptorType.complex);
  }

  @computed
  get notes() {
    const notesProp = this.notesProp;
    if (!notesProp) return [];
    return notesProp.nodes.map((note: DataNode) => new Note(note, this._task));
  }

  getNote = (noteId: string) => {
    return arraySearch('id', noteId, this.notes);
  }

  @action
  addOrEditNote = (newData: DEditableNote, targetNote?: Note) => {
    if (targetNote) {
      targetNote.text = newData.text;
      targetNote.gpsCoords = newData.gpsCoords;
      targetNote.gpsAccuracy = newData.gpsAccuracy;
    } else {
      // todo check errors when no op_notes property
      const notesProp = this.notesProp;
      if (!notesProp) return;
      notesProp.addElement({
        uid: uuid.v1(),
        [NoteProps.text]: newData.text,
        [NoteProps.gpsCoords]: newData.gpsCoords,
        [NoteProps.gpsAccuracy]: newData.gpsAccuracy,
      });
    }
  }

  map = <S>(callback: (item: Note) => S) => this.notes.map(callback);

  getUnsavedNotes = (): DUnsavedNote[] => {
    const arr: DUnsavedNote[] = [];
    for (const note of this.notes) {
      const uNote = note.getUnsavedData();
      if (uNote !== undefined) arr.push(uNote);
    }
    return arr;
  }
}

enum NoteProps {
  text = 'note_text',
  gpsCoords = 'gpsCoords',
  gpsAccuracy = 'gpsAccuracy',
  addedAt = 'date_added',
}

export class Note {
  private _element: DataNode;
  private readonly _task: Task;
  constructor(note: DataNode, task: Task) {
    makeObservable(this);
    this._element = note;
    this._task = task;
  }

  @computed
  get id() {
    return this._element.id;
  }

  @computed
  get elementData() {
    return this._element.data;
  }
  @computed
  get text(): string {
    return this._element.getValue(NoteProps.text, DescriptorType.text) || '';
  }

  set text(value: string) {
    this._element.setValue(NoteProps.text, DescriptorType.text, value);
  }

  @computed
  get gpsCoords(): string | undefined {
    return this._element.getValue(NoteProps.gpsCoords, DescriptorType.text);
  }
  set gpsCoords(value: string | undefined) {
    this._element.setValue(NoteProps.gpsCoords, DescriptorType.text, value);
  }
  @computed
  get gpsAccuracy(): string | undefined {
    return this._element.getValue(NoteProps.gpsAccuracy, DescriptorType.text);
  }

  set gpsAccuracy(value: string | undefined) {
    this._element.setValue(NoteProps.gpsAccuracy, DescriptorType.text, value);
  }

  @computed
  get addedAt() {
    let added = '';
    const date = this._element.getProp(NoteProps.addedAt, DescriptorType.date);
    if (date) {
      added = date.displayValue;
    }
    return added;
  }

  clone = (): Note => {
    const newData = cloneDeep(this._element.data);
    const newElement = new DataNode(
      newData,
      this._element.descriptor,
      this._element.parent,
      this._element.options,
    );
    return new Note(newElement, this._task);
  }

  getUnsavedData = (): DUnsavedNote | undefined => {
    if (!this._element.version) return undefined;
    const uNote: DUnsavedNote = {
      projectId: this._task.project.id,
      standId: this._task.stand.id,
      taskId: this._task.id,
      uid: this._element.id,
      text: this.text,
    };
    const gpsAccuracy = this.gpsAccuracy;
    if (gpsAccuracy) uNote.gpsAccuracy = gpsAccuracy;
    const gpsCoors = this.gpsCoords;
    if (gpsCoors) uNote.gpsCoords = gpsCoors;
    return uNote;

  }
}
